.mint-details-container {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 20px;
  position: relative;
}

.mint-details-content {
  flex: 1;
  padding: 2.5rem;
  background-color: rgba(42, 47, 58, 0.8);
  border-radius: 8px;
  min-width: 250px;
  max-width: 900px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(97, 218, 251, 0.2);
  color: #61dafb;
  text-shadow: 0 0 10px rgba(97, 218, 251, 0.3);
}

.mint-details-content h1 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.mint-details-content h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.mint-details-content p {
  font-size: 1.2rem;
  line-height: 1.3;
  margin-bottom: 1rem;
}

.mint-info {
  margin-bottom: 2rem;
  text-align: center;
}

.mint-details-content a {
  color: #61dafb;
  text-decoration: underline;
}

.wallet-checker-button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  display: block;
  margin: 0 auto;
}

.wallet-checker-button:hover {
  background-color: #45a049;
}

.social-buttons {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.social-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.social-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.social-button img {
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}

.social-button.discord {
  background-color: #5865F2;
  color: white;
}

.social-button.x {
  background-color: #000000;
  color: white;
}

.social-link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  text-decoration: none;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.social-link:hover {
  transform: scale(1.1) translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.social-link.x {
  background-color: #000000;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.social-link.discord {
  background-color: #5865F2;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.social-link svg {
  width: 20px;
  height: 20px;
}
